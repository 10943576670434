<template>
  <section id="landing" ref="scrollContainer" class="theme_1">
    <landingFeed_1 v-for="(id, index) in itemIds" :id="id" :key="id" :page-number="index + 1"
      :page-size="pageSize" :themeConfig="themeConfig" @last-item-id="updateNextId"
      @feedClicked="handleFeedClicked" />
    <van-loading v-if="isLoading" />
  </section>
</template>

<script>
import '@/css/module/landing/theme_1.scss';
import landingFeed_1 from '@/pages/modules/landingFeed/landingFeed_1.vue';
import { Loading } from 'vant';

export default {
  components: {
    landingFeed_1, [Loading.name]: Loading, //按需加载vant 
  },
  props: {
    themeConfig: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      newsId: 0,
      itemIds: [], // 初始id
      nextId: null,
      pageSize: 6, // 每次请求的数据数量

      isLoading: false // 控制插入操作的状态变量
    };
  },
  mounted () {
    console.log('mounted-------------------');
    const newNewsId = Number(this.$route.query.id);
    console.log(newNewsId);
    if (this.newsId !== newNewsId) {
      this.newsId = newNewsId;
      console.log('News ID updated:', this.newsId);
      this.itemIds.push(newNewsId)
    } else {
      console.log('News ID not changed:', this.newsId);
    }

    setTimeout(() => {
      this.initializeEventListeners();
    }, 500);
  },
  activated () {
    console.log('activated-------------------');
    const newNewsId = Number(this.$route.query.id);
    console.log(newNewsId);
    if (this.newsId !== newNewsId) {
      this.newsId = newNewsId;
      console.log('News ID updated:', this.newsId);
      this.itemIds.push(newNewsId)
    } else {
      console.log('News ID not changed:', this.newsId);
    }

    setTimeout(() => {
      this.initializeEventListeners();
    }, 500);
  },
  deactivated () {
    this.itemIds = []
    this.removeEventListeners();
  },
  beforeDestroy () {
    this.itemIds = []
    this.removeEventListeners();
  },
  methods: {
    updateNextId (id) {
      console.log(`updateNextId:  ${id}`);
      this.nextId = id;
      this.isLoading = false; // 数据返回后，重置加载状态
    },
    handleFeedClicked (data) {
      this.itemIds.splice(0, this.itemIds.length); // 使用 splice 清空数组
      this.scrollToTop();
      this.itemIds.splice(0, 0, data); // 插入新的数据
      this.isLoading = false; // 重置加载状态

      // 输出调试信息
      // console.log(this.itemIds);
      // console.log(data);
    },
    scrollToTop () {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 5);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
    initializeEventListeners () {
      window.addEventListener('scroll', this.triggerListener);
    },
    removeEventListeners () {
      window.removeEventListener('scroll', this.triggerListener);
    },
    triggerListener () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      // 判断距离底部是否小于600px，并且当前没有正在加载的数据
      if (scrollHeight - scrollTop - clientHeight <= 100 && !this.isLoading && this.nextId !== null) {
        console.log('准备插入');
        this.isLoading = true; // 标记正在加载

        // 延迟1秒后插入数据
        setTimeout(() => {
          this.itemIds.push(this.nextId);
          console.log(this.itemIds);
          this.nextId = null; // 重置 nextId 等待下一个LandingItem传递id
          this.isLoading = false; // 插入完成后，重置加载状态
        }, 1000);
      }
    },
  },
};
</script>
